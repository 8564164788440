import RouterConstants from "core/routes/constants";
import { AccountContext } from "modules/Account/context";
import { AccountQuery } from "modules/Account/models";
import { PaymentTable } from "modules/Payment/components/PaymentTable/component";
import PaymentService from "modules/Payment/services";
import PaymentUtils from "modules/Payment/utils";
import React from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

const UpgradePage = (): React.ReactElement | null => {
  const { account } = React.useContext(AccountContext);
  const { id: accountId, proxy_id } = account;

  const history = useHistory();

  const { data: dataSubscription } = useQuery(
    [AccountQuery.account_subscription, accountId],
    async () => {
      try {
        const response = await PaymentService.fetchAccountSubscription(
          accountId
        );
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      enabled: !!accountId,
    }
  );

  // If is subscribed, don't show the page
  React.useEffect(() => {
    if (dataSubscription?.is_subscribed) {
      history.push(RouterConstants.ROOT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSubscription?.is_subscribed]);

  return (
    <>
      <PaymentTable
        accountId={accountId}
        checkoutUrls={PaymentUtils.getUpgradeUrls(
          accountId,
          !!proxy_id,
          history.location.pathname
        )}
      />
    </>
  );
};

export default UpgradePage;
