import React from "react";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import CreateOwnerInvitation from "modules/Team/components/CreateOwnerInvitation";
import Button from "ui-kit/atoms/Button";

interface PaymentRequestProps {
  accountId: number;
}

const PaymentRequest = ({
  accountId,
}: PaymentRequestProps): React.ReactElement => {
  const [openDialog, toggleDialog] = React.useState(false);

  return (
    <>
      <Box sx={{ mt: 15 }}>
        <Typography fontSize={18} fontWeight={500} gutterBottom>
          A part of a team?
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ display: "flex", alignItems: "center", mb: 4 }}
        >
          <div>
            Request your{" "}
            <Tooltip
              title="You may need to ask at your organization for the email of your team owner."
              arrow
              placement="top"
            >
              <Typography
                variant="body2"
                component="span"
                sx={{ textDecoration: "underline" }}
              >
                {" "}
                team owner
              </Typography>
            </Tooltip>{" "}
            to add your account under your organization.
          </div>
        </Typography>
        <Button
          onClick={() => toggleDialog(true)}
          type="button"
          variant="outlined"
          color="primary"
        >
          Request team access
        </Button>
      </Box>
      <CreateOwnerInvitation
        open={openDialog}
        toggleOpen={toggleDialog}
        accountId={accountId}
      />
    </>
  );
};

export default PaymentRequest;
