import { IArrayResponse, IAutocompleteOption, IFormProps } from "core/models";
import { IPerson } from "modules/Person/models";

export enum SearchStrategy {
  "inevitable.strategy.search.GoogleSheetsStrategy" = "inevitable.strategy.search.GoogleSheetsStrategy",
  "inevitable.strategy.search.BasicStrategy" = "inevitable.strategy.search.BasicStrategy",
  "inevitable.strategy.search.SalesNavStrategy" = "inevitable.strategy.search.SalesNavStrategy",
  "inevitable.strategy.search.RecruiterStrategy" = "inevitable.strategy.search.RecruiterStrategy",
  "inevitable.strategy.search.MockDirectActionAddToCampaignStrategy" = "inevitable.strategy.search.MockDirectActionAddToCampaignStrategy",
  "inevitable.strategy.search.GroupStrategy" = "inevitable.strategy.search.GroupStrategy",
}

export enum SearchMatchVariantStrategy {
  "inevitable.strategy.search.GoogleSheetsStrategy" = "Google Sheet",
  "inevitable.strategy.search.BasicStrategy" = "LinkedIn search",
  "inevitable.strategy.search.SalesNavStrategy" = "Sales Navigator",
  "inevitable.strategy.search.RecruiterStrategy" = "Recruiter",
  "inevitable.strategy.search.MockDirectActionAddToCampaignStrategy" = "Webhook list",
  "inevitable.strategy.search.GroupStrategy" = "Group search",
}

export enum SearchMatchFilterLabel {
  "google_sheet" = "Google Sheet",
  "linkedin_search" = "LinkedIn search",
  "sales_nav" = "Sales Nav search",
  "recruiter" = "Recruiter search",
  // "group_search" = "Group search",
}

export enum SearchLicense {
  "sales_nav" = "sales_nav",
  "recruiter" = "recruiter",
}

export enum SearchLicenseLabel {
  "sales_nav" = "Sales Navigator",
  "recruiter" = "Recruiter",
}

export enum SearchMatchFilterStrategy {
  "linkedin_search" = "inevitable.strategy.search.BasicStrategy",
  "google_sheet" = "inevitable.strategy.search.GoogleSheetsStrategy",
  "sales_nav" = "inevitable.strategy.search.SalesNavStrategy",
  "recruiter" = "inevitable.strategy.search.RecruiterStrategy",
  // "group_search" = "inevitable.strategy.search.GroupStrategy",
  "webhook_list" = "inevitable.strategy.search.MockDirectActionAddToCampaignStrategy",
}

export enum SearchVariant {
  "google_sheet" = "google_sheet",
  "other" = "other",
}

export enum SearchStrategyVariant {
  "inevitable.strategy.search.GoogleSheetsStrategy" = "google_sheet",
  "inevitable.strategy.search.BasicStrategy" = "other",
  "inevitable.strategy.search.SalesNavStrategy" = "other",
  "inevitable.strategy.search.RecruiterStrategy" = "other",
  "inevitable.strategy.search.GroupStrategy" = "other",
}

export enum SearchTypeFilters {
  "linkedin_search" = "linkedin_search",
  "google_sheet" = "google_sheet",
  "sales_nav" = "sales_nav",
  // "recruiter" = "recruiter",
  // "group_search" = "group_search",
}

export enum SearchTabs {
  "linkedin_search" = "linkedin_search",
  "google_sheet" = "google_sheet",
  "sales_nav" = "sales_nav",
  // "group_search" = "group_search",
  // "recruiter" = "recruiter",
  "existing_search" = "existing_search",
}

export enum SearchTabIndex {
  "linkedin_search" = 0,
  // "sales_nav" = 1,
  "google_sheet" = 1,
  // "group_search" = 2,
  // "recruiter" = 5,
  "existing_search" = 2,
}

export enum LinkedInSearchVariants {
  "search" = "search",
  "sales" = "sales",
  "talent" = "talent",
  "group" = "groups",
}

export enum SearchStatus {
  "queue" = "Scheduled",
  "processing" = "In progress",
  "processed" = "Finished",
  "failed" = "Failed",
}

export enum SearchStatusMatchToVariant {
  "queue" = 0,
  "processing" = 1,
  "processed" = 2,
  "failed" = 3,
}

export enum SearchStatusFilters {
  "queue" = "queue",
  "processing" = "processing",
  "processed" = "processed",
  "failed" = "failed",
}

export enum SearchResultStatusMatchToVariant {
  "queue" = 1,
  "processed" = 2,
  "failed" = 3,
}

export enum SearchResultStatusFilters {
  "queue" = "queue",
  "processed" = "processed",
  "failed" = "failed",
}

export enum SearchResultStatus {
  "queue" = "Scheduled",
  "processed" = "Processed",
  "failed" = "Failed",
}

export interface ISearchFormInput {
  searchName: string;
  url: string;
  column_to_rename?: {
    [key: string]: string;
  };
}

export interface IColumnToRename {
  key_to_rename: string;
  new_key_name: string;
  delete: boolean;
}

export interface IColumnsToRename {
  columns_to_rename?: IColumnToRename[];
}

export interface ISearchFormValues {
  name: string;
  strategy_data: {
    url: string;
    page?: number;
    total?: number;
    columns_to_rename?: IColumnToRename[];
  };
  strategy: SearchStrategy;
  account?: number;
  campaign?: number;
}

export interface ISearchExtendedFormInput extends ISearchFormInput {
  add_automatically?: boolean;
}

export interface ISearchFormValuesSheetFields {
  strategy_data: {
    url: string;
  };
}

export interface ISearchSheetFieldsColumns {
  columns: string[] | null;
}

export interface ISearchSheetResponse extends ISearchSheetFieldsColumns {
  id: number;
  add_automatically: boolean;
}

export interface ISearchSheetFieldsFormValues
  extends IColumnsToRename,
    ISearchSheetFieldsColumns {
  searchName: string;
  url: string;
  add_automatically?: boolean;
}

export interface ISearchSheetFieldsFormatValues {
  strategy_data: {
    url: string;
    columns_to_rename?: IColumnToRename[];
  };
  name?: string;
  strategy?: SearchStrategy;
  account?: number;
  add_automatically?: boolean;
  campaign?: number;
}

export interface ISearchFormValuesMandatoryAccount extends ISearchFormValues {
  account: number;
}

export interface ISearch extends ISearchFormValuesMandatoryAccount {
  id: number;
  
  status: SearchStatusMatchToVariant;
  created: string;
}

export interface ISearches extends IArrayResponse {
  results: ISearch[];
}

export interface ISearchResult {
  id: number;
  status: SearchResultStatusMatchToVariant;
  person: IPerson | null;
  created: string;
  profile_url: string;
  id_profile_url: string;
  placeholders?: { [key: string]: string };
}

export interface ISearchResults extends IArrayResponse {
  results: ISearchResult[];
}

export type ISearchAutocompleteFormValues = IAutocompleteOption[];

export interface ISearchParams {
  search: ISearch;
}

export interface SearchDefaultFormProps extends IFormProps {
  title: string;
  subtitle: string | React.ReactElement;
  label: string;
  subtitleTooltip: string | undefined;
  isLoading: boolean | undefined;
}

export interface ISearchExample {
  name: string;
  urls: string[];
}
