import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React from "react";
import { HelperInfoProps } from "./HelperInfo";

interface DialogHeaderProps {
  title: string | React.ReactElement;
  onHandleClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  helperProps?: HelperInfoProps;
  previewComponent?: React.ReactElement;
  closeIconProps?: IconButtonProps;
  disableCloseIcon?: boolean;
}

const DialogHeader = ({
  title,
  onHandleClose,
  helperProps,
  previewComponent,
  closeIconProps,
  disableCloseIcon,
}: DialogHeaderProps): React.ReactElement => (
  <DialogTitle>
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Grid container justifyContent="space-between" alignItems="baseline">
          <Typography variant="h6" color="textPrimary">
            {title}
          </Typography>
          {/* {!!helperProps && (
            <Grid item>
              <HelperInfo {...helperProps} />
            </Grid>
          )} */}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" alignItems="center">
          {!!previewComponent && <Grid item>{previewComponent}</Grid>}
          {!!onHandleClose && !disableCloseIcon && (
            <Grid item>
              <IconButton
                aria-label="settings"
                onClick={onHandleClose}
                size="large"
                {...closeIconProps}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  </DialogTitle>
);

DialogHeader.defaultProps = {
  helperProps: undefined,
  onHandleClose: undefined,
  previewComponent: undefined,
};

export default DialogHeader;
