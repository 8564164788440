import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EastIcon from "@mui/icons-material/East";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Container,
  Typography,
} from "@mui/material";
import { lighten } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import ConfigConstant from "core/constants/ConfigConstant";
import ButtonGroupFilter from "core/filters/atoms/ButtonGroupFilter";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import {
  isUserTrialActive,
  isUserTrialAvailable,
  stripePromise,
} from "core/utils/paymentHandler";
import get from "lodash/get";
import { AccountQuery } from "modules/Account/models";
import {
  Currencies,
  ICheckoutSessionFormValues,
  ICheckoutUrls,
  ICustomer,
} from "modules/Payment/models";
import PaymentService from "modules/Payment/services";
import PaymentUtils from "modules/Payment/utils";
import { UserContext } from "modules/User/context";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import PageHeader from "ui-kit/components/PageHeader";
import PaymentRequest from "../PaymentRequest";
import { FeatureList } from "./FeatureList";
import PlanOption from "./PlanOption";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: theme.spacing(4, "auto"),
    padding: 0,
    [theme.breakpoints.up("md")]: {
      maxWidth: 460,
    },
  },
  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3),
  },
  planContainer: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    width: "100%",
  },
  actionButton: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1.5),
    fontSize: "1rem",
  },
  requestBox: {
    marginTop: theme.spacing(10),
  },
  discountChipContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  discountChip: {
    display: "inline-flex",
    borderRadius: 10,
    padding: theme.spacing(0, 2),
    textTransform: "uppercase",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.light,
    letterSpacing: ".05em",
    lineHeight: "1.2rem",
    fontWeight: 700,
    fontSize: ".65rem",
  },
  currencySelector: {
    marginBottom: theme.spacing(2),
  },
  card: {
    maxWidth: 500,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(0, "auto", 8),
  },
  avatar: {
    backgroundColor: lighten(theme.palette.success.light, 0.9),
  },
  check: {
    color: theme.palette.success.main,
  },
}));

interface PaymentTableProps {
  accountId: number;
  checkoutUrls: ICheckoutUrls;
  skipPaymentDialog?: boolean;
  disableRequestTeam?: boolean;
}

export function PaymentTable({
  accountId,
  checkoutUrls,
  skipPaymentDialog,
  disableRequestTeam,
}: PaymentTableProps) {
  const classes = useStyles();

  const {
    user: { id: userId, frontend_state },
  } = useContext(UserContext);
  const customPrice: string = get(frontend_state, "prices.0");

  const [currency, setCurrency] = useState<Currencies>(Currencies.usd);
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAccountSubscription = async () => {
    try {
      const { data } = await PaymentService.fetchAccountSubscription(accountId);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataAccountSubscription } = useQuery(
    [AccountQuery.account_subscription, accountId],
    () => fetchAccountSubscription(),
    {
      keepPreviousData: true,
    }
  );

  const fetchSubscription = async () => {
    try {
      const { data } = await PaymentService.fetchAllUserSubscriptions();
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: dataAccountSubscriptions } = useQuery(
    ["subscriptions", userId],
    () => fetchSubscription(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!userId,
    }
  );

  const fetchCustomers = async () => {
    try {
      const { data } = await PaymentService.fetchCustomers();
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataCustomers } = useQuery(
    ["customers"],
    () => fetchCustomers(),
    {
      keepPreviousData: true,
    }
  );

  const fetchPrices = async () => {
    try {
      const { data } = await PaymentService.fetchPrices();
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataPrices } = useQuery(
    ["prices", "all"],
    () => fetchPrices(),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const existingCustomer: ICustomer | undefined = useMemo(
    () => get(dataCustomers, "results[0]"),
    [dataCustomers]
  );

  // Handle currency setting
  useEffect(() => {
    if (existingCustomer?.currency) {
      setCurrency(existingCustomer.currency);
      return;
    }

    const subscriptionCurrency = get(
      dataAccountSubscription,
      "subscriptions[0].data.currency"
    );

    if (subscriptionCurrency) {
      setCurrency(subscriptionCurrency);
    }
  }, [existingCustomer, dataAccountSubscription]);

  const fullPrices = useMemo(
    () =>
      dataPrices?.results.filter(
        (p) =>
          p.data.nickname === ConfigConstant.PRICE.FULL.NICKNAME &&
          p.data.currency === currency
      ),
    [dataPrices, currency]
  );

  const customPriceObj = useMemo(
    () => dataPrices?.results.find((p) => p.id === customPrice),
    [dataPrices, customPrice]
  );

  const isTrialAvailable = useMemo(
    () => isUserTrialAvailable(dataAccountSubscriptions?.results),
    [dataAccountSubscriptions]
  );

  const isTrialActive = useMemo(
    () => isUserTrialActive(dataAccountSubscription?.subscriptions),
    [dataAccountSubscription]
  );

  const isSubscriptionActive = useMemo(
    () => !isTrialActive && dataAccountSubscription?.is_subscribed,
    [dataAccountSubscription, isTrialActive]
  );

  const mutateOnClick = useMutation(
    (price: ICheckoutSessionFormValues["price"]) =>
      PaymentService.createSubscriptionSession({
        price,
        account: accountId,
        ...checkoutUrls,
      }),
    {
      onMutate: () => {
        setIsLoading(true);
      },
      onSuccess: async (response) => {
        const stripe = await stripePromise;
        stripe?.redirectToCheckout({
          sessionId: response.data.id,
        });
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
        setIsLoading(false);
      },
      onSettled: () => {
        // If the redirect doesn't happen (e.g., due to an error), ensure we reset the loading state
        setIsLoading(false);
      },
    }
  );

  const isNotFirstUpdate = useRef(false);
  useEffect(() => {
    if (isNotFirstUpdate.current) {
      return;
    }

    if (fullPrices?.length) {
      setSelectedPlanId(fullPrices?.[0]?.id || null);
      isNotFirstUpdate.current = true;
    }
  }, [fullPrices]);

  // Handle currency change
  const handleCurrencyChange = (newCurrency: Currencies) => {
    setCurrency(newCurrency);
  };

  const contentSuccess = (
    <Card className={classes.card} sx={{ mt: 6 }}>
      <CardHeader
        avatar={
          <Avatar className={classes.avatar}>
            <CheckCircleIcon className={classes.check} />
          </Avatar>
        }
        title="Thanks for subscribing."
        titleTypographyProps={{
          paragraph: true,
          variant: "h6",
        }}
        subheader="Your plan is active now and you can continue with your LinkedIn outreach."
        subheaderTypographyProps={{
          color: "textSecondary",
          variant: "body2",
        }}
      />
    </Card>
  );

  const contentCheckout = (
    <Container className={classes.root}>
      <PageHeader
        title={isTrialActive ? "Upgrade to Unlimited" : "Upgrade to continue"}
        body={
          isTrialActive
            ? "Your trial ends soon. Please upgrade to continue."
            : "Your account is paused. Please upgrade your plan."
        }
      />
      <FeatureList />
      {!!fullPrices && !existingCustomer?.currency && (
        <Box className={classes.currencySelector}>
          <ButtonGroupFilter
            id="filter"
            options={[
              { id: "usd", name: "USD", query: {} },
              { id: "eur", name: "EUR", query: {} },
            ]}
            handleOnChange={handleCurrencyChange}
          />
        </Box>
      )}
      <div className={classes.discountChipContainer}>
        {!!existingCustomer?.discount && (
          <div className={classes.discountChip}>
            {PaymentUtils.formatCouponDiscount(
              existingCustomer?.discount?.coupon
            )}{" "}
            off
          </div>
        )}
      </div>
      <Box className={classes.planContainer}>
        <PlanOption
          plan={fullPrices?.[0]}
          isSelected={selectedPlanId === fullPrices?.[0]?.id}
          onSelect={setSelectedPlanId}
          currency={currency}
          existingCustomer={existingCustomer}
          defaultInterval="month"
          defaultIntervalCount={1}
        />
        <PlanOption
          plan={fullPrices?.[1]}
          isSelected={selectedPlanId === fullPrices?.[1]?.id}
          onSelect={setSelectedPlanId}
          currency={currency}
          existingCustomer={existingCustomer}
          defaultInterval="year"
          defaultIntervalCount={1}
        />
        {!!customPriceObj && (
          <PlanOption
            key={customPriceObj?.id}
            plan={customPriceObj}
            isSelected={selectedPlanId === customPriceObj?.id}
            onSelect={setSelectedPlanId}
            currency={currency}
            existingCustomer={existingCustomer}
            isCustomPrice={true}
          />
        )}
      </Box>
      <LoadingButton
        onClick={() => {
          if (selectedPlanId) {
            mutateOnClick.mutate(selectedPlanId);
          }
        }}
        type="button"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.actionButton}
        disabled={!selectedPlanId}
        endIcon={<EastIcon />}
        loading={isLoading}
      >
        {isTrialAvailable ? "Start free trial" : "Proceed to checkout"}
      </LoadingButton>
      <Typography variant="caption" mt={4}>
        Need help? Click the chat icon to contact us.
      </Typography>

      {!disableRequestTeam && (
        <Box className={classes.requestBox}>
          <PaymentRequest accountId={accountId} />
        </Box>
      )}
    </Container>
  );

  return isSubscriptionActive ? contentSuccess : contentCheckout;
}
