import React from "react";
import ConfigConstant from "core/constants/ConfigConstant";
import RouterConstants from "core/routes/constants";
import {
  ICheckoutUrls,
  ISubscription,
  ISubscriptionPlan,
  SubscriptionStatus,
  SubscriptionStatusColors,
  Currencies,
  CurrencySign,
  ICoupon,
  IPrice,
  CancelationSteps,
  CancelationIds,
} from "../models";
import { Chip } from "ui-kit/atoms/Chip";
import PaymentConstants from "../constants";
import { CountryIds } from "core/models";
import { formatDateToFull } from "core/utils/dateHandler";

type IChipColors =
  | "primary"
  | "secondary"
  | "error"
  | "success"
  | "warning"
  | undefined;

const getChipColor = (status: SubscriptionStatus | undefined): IChipColors => {
  if (!status) {
    return "error";
  }

  return SubscriptionStatusColors[status];
};

const formatPercentage = (n: number): string => `${n}%`;

const calculatePercentage = (n1: number, n2: number): number => {
  return Number(((100 * n1) / n2).toFixed(0));
};

const calculateFixedPercentage = (n1: number, n2: number): number => {
  const v = calculatePercentage(n1, n2);
  return Math.ceil(v / 10) * 10;
};

const getCheckoutUrls = (accountId: number): ICheckoutUrls => ({
  success_url: `${ConfigConstant.BASE_URL}${RouterConstants.ROOT}`,
  cancel_url: `${ConfigConstant.BASE_URL}${RouterConstants.ACCOUNT.new(
    accountId
  )}`,
});

const getUpgradeUrls = (
  accountId: number,
  accountHasProxy: boolean,
  currentPath: string
) => ({
  success_url: `${ConfigConstant.BASE_URL}${RouterConstants.ROOT}`,
  cancel_url: `${ConfigConstant.BASE_URL}${currentPath}`,
});

const formatPrice = (n: number): string => {
  return n.toFixed(2).replace(/[.,]00$/, "");
};

const formatPriceCurrency = (price: number, currency: Currencies) => {
  const formattedPrice = formatPrice(price);
  if (currency === Currencies.usd) {
    return `${CurrencySign.usd}${formattedPrice}`;
  }
  if (currency === Currencies.eur) {
    return `${formattedPrice}${CurrencySign.eur}`;
  }
  return "Not existing currency";
};

const formatStripeUnitPrice = (n: number): number => Math.floor(n / 100);

const formatStripePriceCurrencyInterval = (plan: ISubscriptionPlan): string => {
  const price = formatStripeUnitPrice(plan.amount);
  return `${formatPriceCurrency(price, plan.currency)} / ${plan.interval}`;
};

const calculateDiscountAmount = (
  amount: number,
  coupon: ICoupon | undefined
) => {
  if (!coupon) {
    return 0;
  }
  if (coupon.amount_off) {
    return coupon.amount_off;
  }
  if (coupon.percent_off) {
    return (coupon.percent_off * amount) / 100;
  }
  return 0;
};

const formatStripeAmountCurrency = (
  amount: number,
  currency: Currencies
): string => {
  const price = formatStripeUnitPrice(amount);
  return formatPriceCurrency(price, currency);
};

const formatCouponDiscount = (coupon: ICoupon | undefined): string => {
  if (!coupon) {
    return "";
  }
  if (coupon.percent_off) {
    return formatPercentage(coupon.percent_off);
  }
  if (coupon.amount_off) {
    return formatStripeAmountCurrency(coupon.amount_off, coupon.currency);
  }
  return "Unknown";
};

const formatCouponAmountCurrency = (
  price: ISubscriptionPlan["amount"],
  currency: ISubscriptionPlan["currency"],
  coupon: ICoupon | undefined
): string => {
  let amount = price;
  if (coupon) {
    amount = calculateDiscountAmount(price, coupon);
  }
  return formatStripeAmountCurrency(amount, currency);
};

const calculatePlanWithDiscount = (
  plan: ISubscriptionPlan,
  coupon: ICoupon
): string => {
  const discountAmount = calculateDiscountAmount(plan.amount, coupon);
  const totalAmount = plan.amount - discountAmount;
  const price = formatStripeUnitPrice(totalAmount);
  return `${formatPriceCurrency(price, plan.currency)} / ${plan.interval}`;
};

const calculatePriceWithDiscount = (
  amount: IPrice["data"]["unit_amount"],
  currency: IPrice["data"]["currency"],
  recurring: IPrice["data"]["recurring"],
  coupon: ICoupon | undefined
): number => {
  const discountAmount = calculateDiscountAmount(amount, coupon);
  const totalAmount = amount - discountAmount;
  const price = formatStripeUnitPrice(totalAmount);
  // return `${formatPriceCurrency(price, currency)}`;
  return price;
};

const getPaymentOwner = (subscription: ISubscription | undefined) => {
  if (!subscription) {
    return "-";
  }

  if (subscription.current_user_is_owner) {
    return (
      <Chip variant="outlined" size="small" color="success" label="Owner" />
    );
  }

  return (
    <Chip
      variant="outlined"
      size="small"
      color="warning"
      label="No - can't edit"
    />
  );
};

const getLocaleCurrency = (country: CountryIds) =>
  PaymentConstants.EUR_COUNTRIES.includes(country)
    ? Currencies.eur
    : Currencies.usd;

const getSubscriptionStatusLabel = (
  subscription: ISubscription | undefined,
  is_subscribed: boolean,
  ltd: boolean
) => {
  if (ltd) {
    return (
      <Chip
        variant="outlined"
        size="small"
        color="success"
        label="Free access"
      />
    );
  }

  if (is_subscribed && !subscription?.status) {
    return (
      <Chip variant="outlined" size="small" color="success" label="Active" />
    );
  }

  if (subscription?.status) {
    return subscription.cancel_at ? (
      <Chip
        variant="outlined"
        sx={{ textTransform: "capitalize" }}
        size="small"
        color="warning"
        label={`Ends ${formatDateToFull(subscription.cancel_at, true)}`}
      />
    ) : (
      <Chip
        variant="outlined"
        sx={{ textTransform: "capitalize" }}
        size="small"
        color={getChipColor(subscription?.status)}
        label={subscription?.status}
      />
    );
  }

  return (
    <Chip
      variant="outlined"
      size="small"
      color="error"
      label="No subscription"
    />
  );
};

const getCancelItem = (
  step: CancelationSteps,
  value: CancelationIds | undefined
):
  | {
      title: string;
      subtitle: string | React.ReactElement;
      btn: string;
      component: React.ReactElement;
    }
  | undefined => {
  if (!!value && step === CancelationSteps.offer) {
    return PaymentConstants.CANCEL_STEPS[step][value];
  }

  if (
    CancelationSteps.feedback === step ||
    CancelationSteps.reason === step ||
    CancelationSteps.confirm === step
  ) {
    return PaymentConstants.CANCEL_STEPS[step];
  }

  return undefined;
};

const PaymentUtils = {
  formatPriceCurrency,
  formatPercentage,
  calculatePercentage,
  calculateFixedPercentage,
  getChipColor,
  getCheckoutUrls,
  getUpgradeUrls,
  formatStripeUnitPrice,
  formatStripePriceCurrencyInterval,
  formatStripeAmountCurrency,
  formatCouponAmountCurrency,
  calculatePlanWithDiscount,
  calculatePriceWithDiscount,
  formatCouponDiscount,
  getPaymentOwner,
  getSubscriptionStatusLabel,
  getLocaleCurrency,
  getCancelItem,
};

export default PaymentUtils;
