import React from "react";
import { Box, Typography } from "@mui/material";
import Check from "@mui/icons-material/Check";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  featureItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  featureIcon: {
    color: theme.palette.success.light,
    fontSize: "1.2rem",
    marginRight: theme.spacing(1),
  },
  featureText: {
    "& span": {
      fontWeight: 600,
    },
  },
}));

interface FeatureListItemProps {
  text: string;
}

export const FeatureListItem: React.FC<FeatureListItemProps> = ({ text }) => {
  const classes = useStyles();

  return (
    <Box className={classes.featureItem}>
      <Check className={classes.featureIcon} />
      <Typography variant="body2" className={classes.featureText}>
        {text
          .split("**")
          .map((part, index) =>
            index % 2 === 0 ? part : <span key={index}>{part}</span>
          )}
      </Typography>
    </Box>
  );
};
