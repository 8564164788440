/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import minMax from "dayjs/plugin/minMax";
import isoWeek from "dayjs/plugin/isoWeek";
import calendar from "dayjs/plugin/calendar";
import isToday from "dayjs/plugin/isToday";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import relativeTime from "dayjs/plugin/relativeTime";
import DateConstant from "core/constants/DateConstant";
import { IDateStringRange, ILocaleProps } from "modules/Report/models";
import { Days, TimeZones } from "modules/Account/models";
import { DiffInterval } from "core/models";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);
dayjs.extend(minMax);
dayjs.extend(calendar);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.extend(isToday);

const setDefaultTimezone = (timeZone: string) => {
  dayjs.tz.setDefault(timeZone);
};

// Try to return tz date, return current local date if not available
const getCurrentTimezoneDate = (timeZone?: string) => {
  try {
    return timeZone ? dayjs().tz(timeZone) : dayjs().tz();
  } catch (e) {
    return dayjs();
  }
};

const getTimezoneDate = (d: Date | string | Dayjs) => dayjs(d).tz();

const formatStrToTime = (value: string): Dayjs =>
  dayjs(value, DateConstant.TIME_FORMAT_24);
const formatTimeToStr = (value: Dayjs): string =>
  dayjs(value).format(DateConstant.TIME_FORMAT_24);

const formatStrToDate = (value: string): Dayjs | null =>
  value ? dayjs(value, DateConstant.DATE_FORMAT) : null;
const formatDateToStr = (value: Dayjs | Date | null): string | null =>
  value ? dayjs(value).format(DateConstant.DATE_FORMAT) : null;

const formatDayjsToStr = (value: Dayjs | null): string =>
  value ? value.format(DateConstant.DATE_FORMAT) : "";

const startOfTimezoneDay = () => getCurrentTimezoneDate().startOf("day");
const endOfTimezoneDay = () => getCurrentTimezoneDate().endOf("day");

const startOfTimezoneMonth = () => getCurrentTimezoneDate().startOf("month");

const isLessThanNHoursInThePast = (hours: number, value: string) => {
  return value ? dayjs(value) > dayjs().subtract(hours, "hours") : null;
};

const getFormattedTimezone = (timezone: string) => {
  const currentTimeZoneDate = getCurrentTimezoneDate();
  const offset = currentTimeZoneDate.utcOffset() / 60;
  const sign = offset > 0 ? "+" : "";
  const currentTime = formatDateTimeToSmartTime(currentTimeZoneDate);
  const formattedTimezone = formatTimeZoneName(timezone);
  return `(GMT ${sign}${offset}) ${formattedTimezone} [${currentTime}]`;
};

/**
 * @desc Takes a `date` and converts it to an easy to read `string`.
 * Date is formatted by pretty date format easily readable
 *
 * @param date the date which should be converted into a formatted date
 * @returns readable date string
 */
function formatDateToPretty(date: Date | string | Dayjs): string {
  try {
    return getTimezoneDate(date).format(DateConstant.DATE_PRETTY_FORMAT);
  } catch (e) {
    return date.toString();
  }
}

function formatTimeAgo(date: Date | string | Dayjs, suffix = false): string {
  try {
    let formatString = DateConstant.DATE_PRETTY_FORMAT;
    const userDate = getTimezoneDate(date);
    const diff = Math.abs(userDate.diff(getCurrentTimezoneDate(), "day"));
    if (diff < 1) {
      return userDate.fromNow(suffix);
    }

    if (!userDate.isSameOrAfter(getCurrentTimezoneDate(), "year")) {
      formatString = DateConstant.DATE_PRETTY_FORMAT_YEAR;
    }

    return dayjs.tz(date).format(formatString);
  } catch (e) {
    return date.toString();
  }
}

function formatDateToFull(
  date: Date | string | Dayjs,
  disableTz = false
): string {
  let formatString = DateConstant.DATE_FULL_FORMAT;
  try {
    let formatString = DateConstant.DATE_FULL_FORMAT;
    const userDate = disableTz ? dayjs(date) : getTimezoneDate(date);
    const currentDate = disableTz ? dayjs() : getCurrentTimezoneDate();

    if (!userDate.isSameOrAfter(currentDate, "year")) {
      formatString = DateConstant.DATE_PRETTY_FORMAT_YEAR;
    }
    return userDate.format(formatString);
  } catch (e) {
    if (!date) {
      return "-";
    }
    return dayjs(date).format(formatString);
  }
}

function formatDateTimeToFull(
  date: Date | string | Dayjs,
  disableTz = false
): string {
  try {
    let formatString = DateConstant.DATE_TIME_FULL_FORMAT;
    const userDate = disableTz ? dayjs(date) : getTimezoneDate(date);

    return userDate.format(formatString);
  } catch (e) {
    return date.toString();
  }
}

function formatDateTimeToTime(date: Date | string | Dayjs): string {
  try {
    const userDate = getTimezoneDate(date);
    return userDate.format(DateConstant.TIME_FORMAT);
  } catch (e) {
    return date.toString();
  }
}

function formatUserDateToTime(date: Date | string | Dayjs): string {
  try {
    const dateTime = dayjs(date);
    return dateTime.format(DateConstant.TIME_FORMAT);
  } catch (e) {
    return date.toString();
  }
}

function formatRangeDate({ start, end }: IDateStringRange): string {
  const currentTimezoneDay = getCurrentTimezoneDate();
  const currentTimezoneString = formatDayjsToStr(currentTimezoneDay);

  if (
    start === formatDayjsToStr(currentTimezoneDay.subtract(7, "day")) &&
    end === currentTimezoneString
  ) {
    return "Last 7 days";
  }

  if (start === currentTimezoneString && end === currentTimezoneString) {
    return "Today";
  }

  const startDate = dayjs(start);
  const endDate = dayjs(end);

  if (
    formatDayjsToStr(startDate.add(1, "day")) === currentTimezoneString &&
    formatDayjsToStr(endDate.add(1, "day")) === currentTimezoneString
  ) {
    return "Yesterday";
  }

  if (
    startDate.isSame(endDate, "day") &&
    startDate.isSame(currentTimezoneDay, "year")
  ) {
    return startDate.format(DateConstant.DATE_SIMPLE_PRETTY_FORMAT);
  }

  const isSameYear = startDate.isSame(currentTimezoneDay, "year");
  const formatStr = isSameYear
    ? DateConstant.DATE_SIMPLE_PRETTY_FORMAT
    : DateConstant.DATE_PRETTY_FORMAT_YEAR;

  return `${startDate.format(formatStr)} - ${endDate.format(formatStr)}`;
}

function formatDateTimeToSmartTime(date: Date | string | Dayjs): string {
  try {
    let formatString = DateConstant.TIME_FORMAT;
    const userDate = getTimezoneDate(date);

    if (!userDate.isSameOrAfter(getCurrentTimezoneDate(), "day")) {
      formatString = DateConstant.DATE_TIME_FULL_FORMAT;
    }

    if (!userDate.isSameOrAfter(getCurrentTimezoneDate(), "year")) {
      formatString = DateConstant.DATE_PRETTY_FORMAT_YEAR;
    }

    return userDate.format(formatString);
  } catch (e) {
    return date.toString();
  }
}

function formatDateWithFormatString(
  date: Date | string | Dayjs,
  locale: ILocaleProps
): string {
  try {
    return dayjs.tz(date).calendar(getCurrentTimezoneDate(), locale);
  } catch (e) {
    return date.toString();
  }
}

const differenceInUnits = (
  d1: string | Date | Dayjs | undefined,
  d2: string | Date | Dayjs | undefined,
  unit: "s" | "d"
) => {
  const date1 = dayjs(d1);
  const date2 = dayjs(d2);
  const diff = date1.diff(date2, unit);
  return diff;
};

const differenceByInterval = (
  d1: string | Dayjs | undefined,
  d2: string | Dayjs | undefined,
  defaultInterval: DiffInterval
): String => {
  let interval = defaultInterval;
  if (!d1 || !d2) {
    return "";
  }
  const date1 = dayjs(d1);
  const date2 = dayjs(d2);
  let count = date1.diff(date2, interval);

  if (count > 0 && DiffInterval.day) {
    count = count + 1;
  }

  if (count === 0 && DiffInterval.day) {
    interval = DiffInterval.hour;
    count = date1.diff(date2, interval);
  }

  const intervalLabel = count > 1 ? `${interval}s` : interval;
  return `${count} ${intervalLabel} left`;
};

const formatMinutesToHoursMinutes = (count: number): String | undefined => {
  if (!count) return "0";
  const hours = Math.floor(count / 60);
  const minutes = count % 60;
  return `${hours ? `${hours} hours` : ""} ${
    minutes ? `${minutes} mins.` : ""
  }`;
};

const parseStripeDate = (date: number | undefined): Dayjs | string => {
  if (!date) return "";
  return dayjs.unix(date);
};

const formatTimeZoneName = (v: string | TimeZones): string => {
  if (typeof v !== "string") return "";
  return v.replace(/_+/g, (match) => match.replace(/_/g, " "));
};

const reFormatTimeZoneName = (v: string | undefined): string => {
  if (typeof v !== "string") return "";
  return v.replace(/ +/g, (match) => match.replace(/ /g, "_"));
};

const OTHER = "OTHER";
const YEAR_DAYS = 365;
const MONTH_DAYS = 30;

const formatDelta = (value: string): string | undefined => {
  if (value === OTHER) return undefined;
  let days = 0;
  let timeString = value;
  const hasDays = timeString.length > "00:00:00".length;
  if (hasDays) {
    const splitTimeDelta = timeString.split(" ");

    days = Number(splitTimeDelta[0]);
    timeString = String(splitTimeDelta[1]);
  }
  const splitTimeString = timeString.split(":");

  const hours = Number(splitTimeString[0]);
  const minutes = Number(splitTimeString[1]);
  const years = Math.floor(days / YEAR_DAYS);
  days %= YEAR_DAYS;
  const months = Math.floor(days / MONTH_DAYS);
  days %= MONTH_DAYS;

  const arr = [
    { label: { single: "year", plural: "years" }, value: years },
    { label: { single: "month", plural: "months" }, value: months },
    { label: { single: "day", plural: "days" }, value: days },
    { label: { single: "hour", plural: "hours" }, value: hours },
    { label: { single: "min", plural: "min" }, value: minutes },
  ];
  let time = "";
  arr.forEach((a) => {
    time += a.value
      ? `${a.value} ${a.value === 1 ? a.label.single : a.label.plural} `
      : "";
  });
  return time;
};

const formatNumberToTimeDeltaDays = (n: number): string | null => {
  if (!n) {
    return null;
  }
  return `${n} 00:00:00`;
};
const formatTimeDeltaDaysToNumber = (timeDelta: string | null): number => {
  if (!timeDelta) {
    return 0;
  }
  const splitTimeDelta = timeDelta.split(" ");
  return Number(splitTimeDelta[0]);
};

function isInThePast(date: Date | string | Dayjs): boolean {
  try {
    const now = getCurrentTimezoneDate();
    return dayjs(date).isBefore(now);
  } catch (e) {
    return date < new Date();
  }
}

function isBefore(
  date: Date | string | Dayjs,
  beforeDate: Date | string | Dayjs
): boolean {
  try {
    return dayjs(date).isBefore(dayjs(beforeDate));
  } catch (e) {
    return date < beforeDate;
  }
}

const WEEK_DAY_ARR = [
  Days.sun,
  Days.mon,
  Days.tue,
  Days.wed,
  Days.thu,
  Days.fri,
  Days.sat,
];

const getUserTimezone = (): string => dayjs.tz.guess();

const formatToSmartTime = (time: string) => {
  return formatDateTimeToSmartTime(formatStrToTime(time));
};

// Function to calculate days to prepend
const calculatePrependedDays = (
  chartFirstDay: Dayjs | string | undefined,
  dataFirstDay: Dayjs | string | undefined
): number => {
  if (!chartFirstDay || !dataFirstDay) {
    return 0;
  }
  if (dayjs(chartFirstDay).isSameOrAfter(dayjs(dataFirstDay))) {
    return 0;
  }
  return dayjs(dataFirstDay).diff(dayjs(chartFirstDay), "day");
};

export {
  dayjs,
  setDefaultTimezone,
  getTimezoneDate,
  getCurrentTimezoneDate,
  startOfTimezoneDay,
  endOfTimezoneDay,
  startOfTimezoneMonth,
  formatStrToTime,
  formatTimeToStr,
  formatStrToDate,
  formatDateToStr,
  formatDayjsToStr,
  formatDateTimeToFull,
  formatUserDateToTime,
  formatDateTimeToTime,
  formatDateTimeToSmartTime,
  formatRangeDate,
  formatDateToFull,
  formatDateToPretty,
  formatTimeAgo,
  formatTimeZoneName,
  reFormatTimeZoneName,
  formatDateWithFormatString,
  formatDelta,
  WEEK_DAY_ARR,
  isLessThanNHoursInThePast,
  parseStripeDate,
  differenceByInterval,
  formatMinutesToHoursMinutes,
  formatNumberToTimeDeltaDays,
  formatTimeDeltaDaysToNumber,
  getUserTimezone,
  getFormattedTimezone,
  formatToSmartTime,
  isInThePast,
  isBefore,
  differenceInUnits,
  calculatePrependedDays,
};
