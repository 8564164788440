import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import get from "lodash/get";
import PaymentUtils from "modules/Payment/utils";
import { ITeamInvite } from "modules/Team/models";
import TeamService from "modules/Team/services";
import { UserContext } from "modules/User/context";
import React from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import DialogHeader from "ui-kit/components/DialogHeader";
import { PaymentTable } from "../PaymentTable/component";

interface PaymentDialogProps {
  accountId: number | undefined;
  accountHasProxy: boolean;
  open: boolean;
  onClose: () => void;
}

const PaymentDialog = ({
  accountId,
  accountHasProxy,
  open,
  onClose,
}: PaymentDialogProps): React.ReactElement | null => {
  const location = useLocation();
  const { user } = React.useContext(UserContext);

  const { data: dataAccountInvites } = useQuery(
    ["invites", accountId],
    async () => {
      try {
        const response = await TeamService.fetchAccountTeamInvites(
          Number(accountId)
        );
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!accountId,
    }
  );

  if (!accountId) {
    return null;
  }

  const accountInvite: ITeamInvite = get(dataAccountInvites, "results[0]");

  return (
    <Dialog
      open={open}
      onClose={onClose}
      keepMounted
      PaperProps={{ sx: { maxWidth: 500, width: "100%" } }}
    >
      <DialogHeader
        title=""
        onHandleClose={onClose}
        closeIconProps={{
          sx: {
            position: "absolute",
            right: 10,
          },
        }}
      />
      {user?.id === accountInvite?.inviter?.id && accountInvite && (
        <Typography variant="h6" sx={{ mb: 6, px: 8, pt: 3 }}>
          {`${accountInvite.inviter.name} asked you to add a plan for their account (${accountInvite.account.login})`}
        </Typography>
      )}

      <Box sx={{ mb: 6, px: 8, pt: 3 }}>
        <PaymentTable
          accountId={accountId}
          checkoutUrls={PaymentUtils.getUpgradeUrls(
            accountId,
            accountHasProxy,
            location.pathname
          )}
          disableRequestTeam={true}
        />
      </Box>
    </Dialog>
  );
};

export default PaymentDialog;
