import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Box, Container, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/styles";
import { DiffInterval } from "core/models";
import {
  dayjs,
  differenceByInterval,
  parseStripeDate,
} from "core/utils/dateHandler";
import { AccountContext } from "modules/Account/context";
import { AccountQuery } from "modules/Account/models";
import PaymentDialog from "modules/Payment/components/PaymentDialog";
import { ISubscription, SubscriptionStatus } from "modules/Payment/models";
import PaymentService from "modules/Payment/services";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

const TrialBar = (): React.ReactElement | null => {
  const theme = useTheme();
  const {
    account: { id: accountId, proxy_id, person: isInitialSync },
  } = React.useContext(AccountContext);

  const [subscription, setSubscription] = useState<ISubscription | undefined>(
    undefined
  );
  const [dialogOpen, setDialogOpen] = useState(false); // State to manage dialog visibility

  const fetchAccountSubscription = async () => {
    try {
      const response = await PaymentService.fetchAccountSubscription(accountId);
      return response.data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data } = useQuery(
    [AccountQuery.account_subscription, accountId],
    () => fetchAccountSubscription(),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!accountId,
    }
  );

  useEffect(() => {
    if (data?.subscriptions) {
      const newSubscription = data.subscriptions.find(
        (x) => !x.canceled_at && !x.deleted
      );
      setSubscription(newSubscription);
    } else {
      setSubscription(undefined);
    }
  }, [data?.subscriptions]);

  if (
    !subscription || // If no subscription
    subscription.status !== SubscriptionStatus.trialing || // If not trialing
    !isInitialSync // If not synced
  ) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.app.palette.shield,
        padding: theme.spacing(2, 0),
        zIndex: 2,
        flexGrow: 1,
      }}
    >
      <Container maxWidth="sm" sx={{ height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
            gap: 3,
          }}
          data-cy="trial-menu"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              height: "100%",
              gap: 2,
            }}
          >
            <AccessTimeIcon
              sx={{
                color: theme.palette.common.white,
                fontSize: 21,
                fontWeight: 500,
                WebkitFontSmoothing: "subpixel-antialiased",
              }}
            />
            <Typography
              variant="body2"
              color="common.white"
              sx={{
                fontWeight: 600,
                WebkitFontSmoothing: "subpixel-antialiased",
              }}
            >
              {differenceByInterval(
                parseStripeDate(subscription?.data?.trial_end),
                dayjs(),
                DiffInterval.day
              )}{" "}
              in your trial.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              height: "100%",
              gap: 2,
            }}
          >
            <Button
              sx={{
                border: `1.5px solid ${theme.palette.common.white}`,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.common.white,
                fontWeight: 600,
                WebkitFontSmoothing: "subpixel-antialiased",
                "&:hover": {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.app.palette.shadow.secondary,
                  borderColor: theme.app.palette.shadow.secondary,
                },
              }}
              size="small"
              variant="contained"
              onClick={() => setDialogOpen(true)} // Open dialog on button click
            >
              Upgrade Now
            </Button>
          </Box>
        </Box>
      </Container>

      <PaymentDialog
        accountId={accountId}
        accountHasProxy={!!proxy_id}
        open={dialogOpen} // Pass open state
        onClose={() => setDialogOpen(false)} // Pass onClose handler
      />
    </Box>
  );
};

export default TrialBar;
